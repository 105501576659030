// @ts-nocheck
/**
 * This is a modified version of the code supplied by UserGuiding to add a 'container' to an application
 * for more information see: https://help.userguiding.com/en/articles/2869066-what-are-the-containers
 *
 * @param {containerKey} string container key provided by UserGuiding, required to load UserGuiding
 */

const setupUserGuiding = ({
  g = window,
  u = document,
  i = 'script',
  d = 'userGuiding',
  e = 'userGuidingLayer',
  containerKey,
}) => {
  g[e] = g[e] || [];
  var f = u.getElementsByTagName(i)[0];
  var k = u.createElement(i);
  k.async = true;
  k.src =
    'https://eu-static.userguiding.com/media/user-guiding-' +
    containerKey +
    '-embedded.js';
  f.parentNode.insertBefore(k, f);
  if (g[d]) return;
  var ug = (g[d] = { q: [] });
  ug.c = function (n) {
    return function () {
      ug.q.push([n, arguments]);
    };
  };
  var m = [
    'previewGuide',
    'finishPreview',
    'track',
    'identify',
    'hideChecklist',
    'launchChecklist',
  ];
  for (var j = 0; j < m.length; j += 1) {
    ug[m[j]] = ug.c(m[j]);
  }
};

export default setupUserGuiding;
