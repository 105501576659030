/// <reference types="@commercetools-frontend/application-config/client" />
import ReactDOM from 'react-dom';
import { getParsedConsentCookieGroups } from '@commercetools-frontend/cookie-consent/core';
import { initialize as initializeFullStory } from '@commercetools-frontend/fullstory';
import { initializeUserGuiding } from '@commercetools-frontend/userguiding';
import EntryPoint from './components/entry-point';

const consentGroups = getParsedConsentCookieGroups();
const hasGivenPerformanceCookieConsent = Boolean(
  consentGroups.performanceCookies
);
const hasGivenFunctionalConsent = Boolean(consentGroups.functionalCookies);
// set disable to `false` locally to enable UserGuiding
initializeUserGuiding({
  disable: !hasGivenPerformanceCookieConsent || !hasGivenFunctionalConsent,
});

if (hasGivenPerformanceCookieConsent) {
  initializeFullStory();
}

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(<EntryPoint />, document.getElementById('app'));
