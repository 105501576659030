export default function validateUserGuidingContainerKey(
  containerKey: unknown
): asserts containerKey is string {
  if (typeof containerKey !== 'string') {
    throw new Error('A valid UserGuiding container key must be a string');
  }

  if (containerKey === undefined) {
    throw new Error(
      'A valid UserGuiding container key cannot be an empty string'
    );
  }
}
