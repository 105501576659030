import { useEffect } from 'react';

const ReloadPage = (props: { to?: string }) => {
  useEffect(() => {
    if (props.to) {
      window.location.replace(props.to);
    } else {
      window.location.reload();
    }
  }, [props.to]);

  return null;
};
ReloadPage.displayName = 'ReloadPage';

export default ReloadPage;
