import { defineMessages } from 'react-intl';

const loadReductionMessages = defineMessages({
  title: {
    id: 'PageApplicationDisabled.loadReduction.title',
    defaultMessage: 'The application is temporarily unavailable',
  },
  explanation: {
    id: 'PageApplicationDisabled.loadReduction.explanation',
    defaultMessage:
      'The application is temporarily unavailable due to high load in our platform. We will resume the functionality as soon as possible. Visit our <status-page-link>status page</status-page-link> for more information about the status of the commercetools platform.',
  },
});

const dbMaintenanceMessages = defineMessages({
  title: {
    id: 'PageApplicationDisabled.dbMaintenance.title',
    defaultMessage: 'The application is temporarily unavailable',
  },
  explanation: {
    id: 'PageApplicationDisabled.dbMaintenance.explanation',
    defaultMessage:
      'The application is temporarily unavailable due to a database maintenance. We will resume the functionality as soon as possible. Visit our <status-page-link>status page</status-page-link> for more information about the status of the commercetools platform.',
  },
});

const notFoundMessages = defineMessages({
  title: {
    id: 'PageApplicationDisabled.notFound.title',
    defaultMessage: 'We could not find what you are looking for',
  },
  explanation: {
    id: 'PageApplicationDisabled.notFound.explanation',
    defaultMessage:
      'The page you are looking for is not available.\n\nContact your system administrator or the commercetools <support-portal-link>Help Desk</support-portal-link> if you have any further questions.',
  },
});

export default {
  loadReduction: loadReductionMessages,
  dbMaintenance: dbMaintenanceMessages,
  notFound: notFoundMessages,
};
