import type { HTMLAttributes, ReactNode } from 'react';

export type TMarkingProps = HTMLAttributes<HTMLDivElement> & {
  name: string;
  children: ReactNode;
};

function applyMarker(name: TMarkingProps['name']) {
  return { 'data-tracking': name };
}

function Section({ children, name, ...additionalProps }: TMarkingProps) {
  return (
    <section {...additionalProps} {...applyMarker(name)}>
      {children}
    </section>
  );
}

const Marking = { Section, applyMarker };

export { Marking };
