import {
  mapLocaleToIntlLocale,
  parseChunkImport,
  type TMessageTranslations,
} from '@commercetools-frontend/i18n';
import { reportErrorToSentry } from '@commercetools-frontend/sentry';
import {
  loadI18n as loadLocalI18n,
  // @ts-ignore: The `@commercetools-local/i18n` does not provide type declarations yet.
} from '@commercetools-local/i18n';

const loadAppMessages = (locale: string) => {
  const intlLocale = mapLocaleToIntlLocale(locale);
  switch (intlLocale) {
    case 'de':
      return import(
        /* webpackChunkName: "app-i18n-de" */ './i18n/data/de.json'
      );
    case 'es':
      return import(
        /* webpackChunkName: "app-i18n-es" */ './i18n/data/es.json'
      );
    case 'fr-FR':
      return import(
        /* webpackChunkName: "app-i18n-fr-FR" */ './i18n/data/fr-FR.json'
      );
    case 'pt-BR':
      return import(
        /* webpackChunkName: "app-i18n-pt-BR" */ './i18n/data/pt-BR.json'
      );
    case 'zh-CN':
      return import(
        /* webpackChunkName: "app-i18n-zh-CN" */ './i18n/data/zh-CN.json'
      );
    default:
      return import(
        /* webpackChunkName: "app-i18n-en" */ './i18n/data/en.json'
      );
  }
};

const loadMessages = async (locale: string): Promise<TMessageTranslations> => {
  try {
    const coreMessages = await loadLocalI18n(locale);
    const appMessagesChunkImport = await loadAppMessages(locale);
    return {
      ...coreMessages,
      ...parseChunkImport(appMessagesChunkImport),
    } as TMessageTranslations;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(
      `Something went wrong while loading the core messages for ${locale}`,
      error
    );
    reportErrorToSentry(
      new Error(
        `Something went wrong while loading the core messages for ${locale}`
      ),
      { extra: error }
    );
    return {};
  }
};

export default loadMessages;
