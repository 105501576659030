//@ts-ignore not typed
import setupUserGuiding from './setup-userguiding';
import validateUserGuidingContainerKey from './validate-userguiding-container-key';
declare global {
  interface Window {
    app: Record<string, unknown>;
  }
}

// this is here because ts will not let us have nice things
interface UserGuidingAppEnvWindow extends Window {
  app: Record<string, unknown> & {
    userGuidingContainerKey: string;
  };
}
/**
 * initializeUserGuiding:  🚨🚨🚨This function should be called in the root-level component of your application.  Initializes UserGuiding scripts
 *
 * @param {string} containerKey UserGuiding-supplied key for specific UserGuiding container to load, see https://help.userguiding.com/en/articles/2869066-what-are-the-containers for more details
 * @param {boolean} disable value for whether to disable loading UserGuiding scripts in application
 */
const initializeUserGuiding = ({
  containerKey = (window as unknown as UserGuidingAppEnvWindow).app
    ?.userGuidingContainerKey,
  disable,
}: {
  containerKey?: string;
  disable: boolean;
}) => {
  if (disable) {
    // ✅ does not initialize if disabled
    // eslint-disable-next-line no-console
    console.warn(
      '[@commercetools-frontend/userguiding]: UserGuiding scripts disabled.  Not initializing UserGuiding.'
    );
    return;
  }

  try {
    try {
      // ✅ does not initialize without valid containerKey
      validateUserGuidingContainerKey(containerKey);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(
        '[@commercetools-frontend/userguiding]: no valid UserGuiding `containerKey` passed.  Not initializing UserGuiding.',
        error
      );

      return;
    }

    setupUserGuiding({ containerKey });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(
      '[@commercetools-frontend/userguiding]: failed to initialize userGuiding due to an error: ',
      error
    );
  }
};

export default initializeUserGuiding;
