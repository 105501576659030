import { useIntl, FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { MaintenancePageLayout } from '@commercetools-frontend/application-components';
import type { TEnhancedLocation } from '@commercetools-frontend/browser-history';
import { SUPPORT_PORTAL_URL } from '@commercetools-frontend/constants';
import { Link } from '@commercetools-frontend/ui-kit';
import { STATUS_PAGE_URL } from '@commercetools-local/constants/src/misc';
import messages from './messages';
import PageApplicationDisabledSvg from './page-application-disabled.svg';

const getSupportPortalLink = (msg: string) => (
  <Link to={SUPPORT_PORTAL_URL} isExternal>
    {msg}
  </Link>
);

const getStatusPageLink = (msg: string) => (
  <Link to={STATUS_PAGE_URL} isExternal>
    {msg}
  </Link>
);

const getMessagesForReason = (reason?: string) => {
  switch (reason) {
    case 'load-reduction': {
      return messages.loadReduction;
    }
    case 'db-maintenance': {
      return messages.dbMaintenance;
    }
    default: {
      return messages.notFound;
    }
  }
};

type TLocationQuery = {
  reason: string;
};

const PageApplicationDisabled = () => {
  const location = useLocation() as TEnhancedLocation<TLocationQuery>;
  const intl = useIntl();

  const reason = location.query.reason;
  const messagesForReason = getMessagesForReason(reason);

  return (
    <MaintenancePageLayout
      imageSrc={PageApplicationDisabledSvg}
      title={intl.formatMessage(messagesForReason.title)}
      label={intl.formatMessage(messagesForReason.title)}
      paragraph1={
        <FormattedMessage
          {...messagesForReason.explanation}
          values={{
            // @ts-ignore: this is fixed in react-intl v6.
            'support-portal-link': getSupportPortalLink,
            // @ts-ignore: this is fixed in react-intl v6.
            'status-page-link': getStatusPageLink,
          }}
        />
      }
    />
  );
};
PageApplicationDisabled.displayName = 'PageNotFound';

export default PageApplicationDisabled;
