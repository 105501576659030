import { lazy } from 'react';
import ErrorBoundary from './components/error-boundary';
import type { TStaffBarContainerProps } from './types';

const StaffBarComponent = lazy(() => import('./staff-bar'));

function StaffBar(props: TStaffBarContainerProps) {
  if (
    process.env.NODE_ENV === 'test' ||
    (window.Cypress && !window.sessionStorage?.enableStaffBarForCypress)
  ) {
    return null;
  }
  return (
    <ErrorBoundary>
      <StaffBarComponent {...props} />
    </ErrorBoundary>
  );
}

export default StaffBar;
