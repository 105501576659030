import { useEffect } from 'react';
import {
  type TApplicationContext,
  useApplicationContext,
} from '@commercetools-frontend/application-shell-connectors';

declare global {
  interface Window {
    userGuiding: Record<string, unknown> & {
      identify: (params: Record<string, string | null | undefined>) => void;
    };
  }
}

type TTrackingArgs = {
  disable?: boolean;
  additionalUserVars?: Record<string, unknown>;
};

type TAdditionalEnvironmentValues = {
  cloudEnvironment: string;
};
type TSelectedEnvironmentValues = {
  user: TApplicationContext<TAdditionalEnvironmentValues>['user'];
  project: TApplicationContext<TAdditionalEnvironmentValues>['project'];
  cloudEnvironment: string;
  businessRole?: string;
};
const defaultTrackingArgs: TTrackingArgs = {
  disable: false,
  additionalUserVars: undefined,
};

/**
 * useTrackingEffect: this method sends specific userdata to UserGuiding for user tracking and segmentation purposes, data sent by default are `uid`, `projectKey`, `environment`, `businessRole`, and `locale`
 *
 * @param {boolean} disable disables sending user data to UserGuiding if set to `true`
 * @param {object} additionalUserVars any additional user data to send to UserGuiding
 */
function useTrackingEffect({
  disable = false,
  additionalUserVars,
}: TTrackingArgs = defaultTrackingArgs) {
  const { project, user, cloudEnvironment } = useApplicationContext<
    TSelectedEnvironmentValues,
    TAdditionalEnvironmentValues
  >((context) => ({
    user: context.user,
    project: context.project,
    cloudEnvironment: context.environment.cloudEnvironment,
  }));

  useEffect(() => {
    // if the UserGuiding `identify` method is not set on `window`, we cannot call it
    if (!window.userGuiding?.identify || disable) {
      return;
    }

    window.userGuiding.identify({
      uid: user?.id,
      environment: cloudEnvironment,
      projectKey: project?.key,
      businessRole: user?.businessRole,
      locale: user?.locale,
      ...additionalUserVars,
    });
  }, [
    disable,
    additionalUserVars,
    cloudEnvironment,
    project?.key,
    user?.businessRole,
    user?.id,
    user?.locale,
  ]);

  return null;
}

export { useTrackingEffect };
