import { css } from '@emotion/react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { InfoDialog } from '@commercetools-frontend/application-components';
import { useApplicationContext } from '@commercetools-frontend/application-shell-connectors';
import {
  Spacings,
  Text,
  PrimaryButton,
  designTokens,
} from '@commercetools-frontend/ui-kit';
import messages from './messages';
import ProjectCreateConfirmationIllustration from './project-create-confirmation.react.svg';

const createRoute = (projectKey: string) => `/${projectKey}/welcome`;

const ProjectCreateConfirmation = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const projectKey = useApplicationContext((context) => context.project!.key);
  const onClose = () => history.push(createRoute(projectKey));

  return (
    <InfoDialog
      title={formatMessage(messages.title)}
      isOpen
      onClose={onClose}
      size={16}
    >
      <div
        css={css`
            margin-bottom: ${designTokens.spacingL}};
          `}
      >
        <Spacings.Stack scale="l" alignItems="center">
          <ProjectCreateConfirmationIllustration />
          <Text.Body intlMessage={messages.bodyText} />
          <PrimaryButton
            label={formatMessage(messages.buttonLabel)}
            onClick={onClose}
          />
        </Spacings.Stack>
      </div>
    </InfoDialog>
  );
};

export default ProjectCreateConfirmation;
