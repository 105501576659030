import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'Welcome.NewProjectCreated.Dialog.title',
    description: 'Title for the dialog',
    defaultMessage: 'New project created',
  },
  bodyText: {
    id: 'Welcome.NewProjectCreated.Dialog.bodyText',
    description: 'Message of the dialog',
    defaultMessage: 'You are now ready to start.',
  },
  buttonLabel: {
    id: 'Welcome.NewProjectCreated.Dialog.buttonLabel',
    description: 'The confirmation button label',
    defaultMessage: 'Get started',
  },
});

export default messages;
