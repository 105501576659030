/**
 * This is the entry point of an application.
 * See `@commercetools-frontend/application-shell` for usage.
 */
import { lazy } from 'react';
import { Redirect, Route, Switch, type RouteProps } from 'react-router-dom';
import * as globalActions from '@commercetools-frontend/actions-global';
import { PageNotFound } from '@commercetools-frontend/application-components';
import {
  ApplicationShell,
  setupGlobalErrorListener,
} from '@commercetools-frontend/application-shell';
import type { ApplicationWindow } from '@commercetools-frontend/constants';
import {
  CookieConsentBanner,
  useCookieConsent,
  useSkipCookieConsent,
} from '@commercetools-frontend/cookie-consent/react';
import { useTrackingEffect as useFullStoryTrackingEffect } from '@commercetools-frontend/fullstory';
import { Sdk } from '@commercetools-frontend/sdk';
import { StaffBar } from '@commercetools-frontend/staff-bar';
import { useTrackingEffect as useUserGuidingTrackingEffect } from '@commercetools-frontend/userguiding';
import {
  ALL_DEFAULT_FLAGS,
  DEFAULT_SHORT_LIVED_FLAGS,
} from '../../constants/feature-toggles';
import applicationMessages from '../../messages';
import PageApplicationDisabled from '../page-application-disabled';
import ProjectCreateConfirmation from '../project-create-confirmation';
import ReloadPage from './reload-page';

declare let window: ApplicationWindow;

const AsyncWelcomeRoutes = lazy(
  () => import('../../routes' /* webpackChunkName: "fallback" */)
);

// Ensure to setup the global error listener before any React component renders
// in order to catch possible errors on rendering/mounting.
setupGlobalErrorListener();

const FallbackCookieConsentBanner = () => {
  const skipCookieConsent = useSkipCookieConsent();

  return <CookieConsentBanner skipConsent={skipCookieConsent} />;
};

type TApplicationFallbackProps = {
  children: RouteProps['children'];
};

export const ApplicationFallback = (props: TApplicationFallbackProps) => {
  const { givenConsent: hasGivenPerformanceConsent } =
    useCookieConsent('performanceCookies');
  const { givenConsent: hasGivenFunctionalConsent } =
    useCookieConsent('functionalCookies');

  useFullStoryTrackingEffect({
    disable: !hasGivenPerformanceConsent,
  });
  // set disable to false in order to enable locally
  useUserGuidingTrackingEffect({
    disable: !hasGivenPerformanceConsent || !hasGivenFunctionalConsent,
  });

  return (
    <>
      <StaffBar featureFlags={DEFAULT_SHORT_LIVED_FLAGS} />
      <Switch>
        {/**
         * NOTE:
         *  The user menu allows navigation to the application-account.
         *  When the application-fallback is rendered and the user intends
         *  to navigate to the application-account we need a fullpage reload
         *  as otherwise we remain within the application-fallback.
         */}
        <Route path="/account">
          <ReloadPage />
        </Route>
        <Redirect exact from="/:projectKey" to="/:projectKey/welcome" />
        {/*
         * NOTE: The project creation workflow points to this route to display a modal.
         * Users confirm from there, and we point them to the welcome page.
         */}
        <Route exact path="/:projectKey/welcome/new">
          <>
            <ProjectCreateConfirmation />
            {props.children}
          </>
        </Route>
        <Route path="/:projectKey/welcome">{props.children}</Route>
        <Route path="/:projectKey/disabled/:applicationName">
          <PageApplicationDisabled />
        </Route>
        <Route>
          <PageNotFound />
        </Route>
      </Switch>
    </>
  );
};
ApplicationFallback.displayName = 'ApplicationFallback';

const EntryPoint = () => (
  <ApplicationShell
    enableReactStrictMode
    environment={window.app}
    featureFlags={DEFAULT_SHORT_LIVED_FLAGS}
    defaultFeatureFlags={ALL_DEFAULT_FLAGS}
    onRegisterErrorListeners={({ dispatch }) => {
      Sdk.Get.errorHandler = (error) =>
        globalActions.handleActionError(error)(dispatch);
    }}
    // @ts-ignore not typed correctly
    applicationMessages={applicationMessages}
    render={() => (
      <ApplicationFallback>
        <FallbackCookieConsentBanner />
        <AsyncWelcomeRoutes />
      </ApplicationFallback>
    )}
  />
);

EntryPoint.displayName = 'EntryPoint';

export default EntryPoint;
